import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import i18n from './i18n';
// import DocumentMeta from 'react-document-meta';
import { Helmet } from "react-helmet";
import me from './assets/me.png';
import App from './App';

var initLang = 'kh';
if(window.location.pathname.length>=3){
  if(window.location.pathname.substr(0, 3)==='/en'){
    initLang = 'en';
  }
}
i18n.changeLanguage(initLang);

ReactDOM.render(
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{i18n.t('Vireak EK')}</title>
      <meta name="keywords" content={`${i18n.t('vireak')},${i18n.t('vireakek')},${i18n.t('ekvireak')},${i18n.t('ek')}`} />
      <meta name="description" content={i18n.t('My personal site!')} />
      <meta property="og:url" content={window.location.origin+window.location.pathname} />
      <meta property="og:type" content={i18n.t('website')} />
      <meta property="og:title" content={i18n.t('Vireak EK')} />
      <meta property="og:description" content={i18n.t('My personal site!')} />
      <meta property="og:image" content={window.location.origin+me} />
    </Helmet>
    <App />
  </>,
  document.getElementById('root')
);

/*
class RootDocument extends React.Component {
  render() {
    const meta = {
      title: i18n.t('Vireak EK'),
      description: i18n.t('My personal site!'),
      canonical: window.location.origin,
      meta: {
        name: {
          keywords: `${i18n.t('vireak')},${i18n.t('vireakek')},${i18n.t('ekvireak')},${i18n.t('ek')}`,
        },
        property:{
          "og:url":window.location.origin+window.location.pathname,
          "og:type":i18n.t('website'),
          "og:title":i18n.t('Vireak EK'),
          "og:description":i18n.t('My personal site!'),
          "og:image":window.location.origin+me,
        },
      }
    };
 
    return (
        <DocumentMeta {...meta}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{i18n.t('Vireak EK')}</title>
              <meta property="og:image" content={window.location.origin+me} />
            </Helmet>
            <App />
        </DocumentMeta>
    );
  }
}
ReactDOM.render(<RootDocument />, document.getElementById('root'));
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
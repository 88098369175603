function NumberTranslate(number, lang){
    if(lang==='kh'){
        var n = ""+number;
        var num = '';
        for(let i=0; i<n.length; i++){
            if(n[i]==='0'){
                num += '០';
            }
            else if(n[i]==='1'){
                num += '១';
            }
            else if(n[i]==='2'){
                num += '២';
            }
            else if(n[i]==='3'){
                num += '៣';
            }
            else if(n[i]==='4'){
                num += '៤';
            }
            else if(n[i]==='5'){
                num += '៥';
            }
            else if(n[i]==='6'){
                num += '៦';
            }
            else if(n[i]==='7'){
                num += '៧';
            }
            else if(n[i]==='8'){
                num += '៨';
            }
            else if(n[i]==='9'){
                num += '៩';
            }
            else{
                num += '';
            }
        }
        return num;
    }
    else{
        return number;
    }
}
export default NumberTranslate;
  